import { createApp } from 'vue';
import App from './App.vue';
import Azacts from './components/Azacts.vue';
import Days from './components/Days.vue';
import Faves from './components/Faves.vue';
import Nownext from './components/NowNext.vue';
import TimeLine from './components/Timeline.vue';
import './registerServiceWorker'

const app = createApp(App)
app.component('azacts-list',Azacts);
app.component('stages-by-day',Days);
app.component('faves-list',Faves);
app.component('now-next',Nownext);
app.component('timeline-view',TimeLine);
app.mount('#app');

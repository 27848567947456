<template>
<section class="container-fluid a-z">
  <h3 class="page-title sticky-top">Acts A-Z</h3>
  <div class="row">
  <div class="list-group list-group-flush">
      <div class="list-group-item d-flex justify-content-between align-items-stretch" v-for="show in shows" :key="show.id" :data-id="show.id" :class="'hcol-' + show.idx">
            <div class="me-auto">
              <div class="fw-bold">{{ show.name }}</div>
              <span class="act-stage"><strong>{{ show.stage }}</strong></span><br>
              <span class="act-details"><strong>{{ show.day }}</strong> {{ show.timespan }}</span>
            </div>
            <div class="ms-4 align-self-center flex-shrink-0 clickable" @click.stop="$emit('manageFavourite',show.id)" :class="faves.includes(show.id) ? 'icon-star-filled' : 'icon-star-empty'"></div>
          </div>
  </div>
  </div>
</section>
</template>

<script>
export default {
    name: 'azacts-list',
    props: {shows: Array,faves: Array,showAZ: Boolean},
    methods: {
      
    }
}
</script>

<template>
<section class="container-fluid day-timeline">
    <h3 class="page-title timeline-h sticky-top">Timeline</h3>
    <div v-if="stageData.length > 0" class="timeline-header sticky-top d-flex flex-row flex-nowrap">
      <div class="times-header flex-grow-0 flex-shrink-0"></div>
      <div v-for="stage,index in timelines" :key="stage.stage" class="stage-header flex-grow-1 flex-shrink-1 d-flex flex-column" :class="'hcol-' + index">
        <h6 class="text-center">{{ stage.stage }}</h6>
      </div>
    </div>
    <div v-if="stageData.length > 0" class="timeline-wrapper d-flex flex-row flex-nowrap">
    <div class="time-grid d-flex flex-column justify-content-between flex-grow-0 flex-shrink-0">
      <div v-for="hour in hours" :key="hour" class="gridline"></div>
    </div>
    <div id="timeline-now-marker" :style="{'top':timeMarker+'px'}" v-show="formatDate == today.date && dateStart <= nowTime && nowTime <= dateEnd"></div>
    <div class="times-wrapper flex-grow-0 flex-shrink-0">
        <div class="times d-flex flex-column justify-content-between flex-grow-0 flex-shrink-0">
          <span v-for="hour in hours" :key="hour">{{ hour }}</span>
        </div>
    </div>
    <div class="timelines-wrapper d-flex flex-row flex-grow-1 flex-shrink-1">
    <div v-for="stage,index in timelines" :key="stage.stage" class="stage-wrapper flex-grow-1 flex-shrink-1 d-flex flex-column" :class="'hcol-' + index">
      <div class="stage-bg"></div>  
      <div v-for="show in stage.shows" class="timeline-entry flex-grow-0 flex-shrink-0 d-flex flex-column justify-content-center" :class="{'performance' : (show.name != 'spacer') && (nowTime <= show.end),'elapsed' : (show.name != 'spacer') && (nowTime > show.end), 'fave' : faves.includes(show.id)}" :style="{'height': (show.duration/dayInSeconds)*1950+'px'}" :key="show.name">
        <span class="p-entry" v-if="show.name != 'spacer'" >{{ show.name }}<br><span class="timeline-entry-time">{{ show.timespan }}</span></span>
      </div>
      </div>
    </div>
    </div>
    <div class="row list-group list-group-flush" v-else>
      <div class="list-group-item">
      {{  noActMsg }}
      </div>
  </div>
</section>
</template>

<script>
export default ({
    name: 'timeline-view',
    props: {stages: Array,today: Object,nowTime: Date,dayStart: String,dayEnd: String,dateStart: Date,dateEnd: Date,timeMarker: Number,dayInSeconds: Number,faves: Array},
    data() {
      return {
        stageData: [],
        timelines: [],
        todayStart: '',
        todayEnd: '',
        tlIncludeStages: ['Main Stage', 'Big Top', 'Little Big Top'],
        noActMsg: 'There are no acts on your chosen stages today'
      }
    },
    mounted() {
      this.todayStart = this.today.date + ' ' + this.dayStart;
      this.todayEnd = this.today.date + ' ' + this.dayEnd;
        this.updateData();
    },
    watch: {
      stages() {
        this.updateData();
      }
    },
    computed: {
      hours() {
      var hours = [];
      var marker = parseInt(this.dayStart.substring(0,2));
      for (var h=0;h<=(parseInt(this.dayEnd.substring(0,2))-parseInt(this.dayStart.substring(0,2)));h++) {
        hours.push(marker+':00');
        marker++;
      }
      hours.push('00:00');
      return hours;
      },
      formatDate() {
      return this.nowTime.getFullYear() + '-' + ('00' + (this.nowTime.getMonth()+1)).slice(-2) + '-' + ('00' + this.nowTime.getDate()).slice(-2);
      }
    },
    methods: {
      updateData() {
        for (var i=0;i<this.stages.length;i++) {
        var thisStage = this.stages[i];
        if (this.tlIncludeStages.includes(thisStage.stage) || this.tlIncludeStages.length == 0) {
        var ts = thisStage.shows.filter(s => ((this.todayStart <= s.start) && (s.start < this.todayEnd))); // s => s.date == this.today.date
        console.log(ts);
        if (ts.length > 0) {
        this.stageData.push({stageid:thisStage.stageid,stage:thisStage.stage,shows:ts});
        this.timelines.push({stage:thisStage.stage,shows:this.createTimeline(thisStage.stage)});
        }
        }
      }
      },
      createTimeline(st) {
        for (var s=0;s<this.stages.length;s++) {
          if (st == this.stages[s].stage) {
            var tl = this.stages[s].shows;
            return this.addSpaces(tl.filter(sh => sh.date == this.today.date));
          }
        }
      },
      addSpaces(sh) {
        if (sh.length > 0) {
        var list = [];
        var timeMarker = new Date(this.today.date);
        timeMarker.setHours(this.dayStart.substring(0,2),this.dayStart.substring(3));
        for (var i=0;i<sh.length;i++) {
          var ss = new Date(sh[i].start);
          var se = new Date(sh[i].end);
          if (ss.getHours() < this.dayStart.substring(0,2)) continue;
          if (se.getHours() < this.dayStart.substring(0,2) && se.getHours() > 0) {
            se.setHours(1);
            se.setMinutes(0);
          }
          if (ss > timeMarker) {
            list.push({name: 'spacer', duration: (ss-timeMarker)/1000});
            list.push({id: sh[i].id, name: sh[i].name, timespan:sh[i].timespan, end: se, duration: (se-ss)/1000});
            timeMarker = se;
          } else {
            list.push({id: sh[i].id, name: sh[i].name, timespan:sh[i].timespan, end: se, duration: (se-ss)/1000});
            timeMarker = se;
          }
        }
        return list;
      } else {
        return null;
      }
    }
    }
})
</script>

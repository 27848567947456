<template>
<nav class="navbar navbar-expand-md fixed-top">
        <div class="container-fluid">
          <ul class="navbar-nav me-auto mb-0 flex-row">
            <li class="nav-item" v-for="day in days" :key="day">
              <a class="nav-link blank-link" :class="{active: currentSlide == 1 && days.indexOf(day)==activeDay &! showAZ}" @click.stop="changeDay(days.indexOf(day))" >{{ day.day }}</a> <!-- aria-current="page" -->
            </li>
            <li class="nav-item">
                <a class="nav-link blank-link" :class="{active: currentSlide == 1 && showAZ}" @click.stop="showAtoZ()">A-Z</a>
            </li>
          </ul>
          <span v-show="currentSlide == 1 && !showAZ" @click.stop="switchDayView" :class="showTimeline ? 'icon-list' : 'icon-timeline'"></span>
          <span @click.stop="switchColourMode" :class="darkMode ? 'icon-light-mode' : 'icon-dark-mode'"></span>
        </div>
      </nav>
<swiper :slides-per-view="1" :initial-slide="1" :space-between="0" @swiper="onSwiper" @slideChange="onSlideChange">
    <swiper-slide>
        <faves-list :faveShows="listFaves" :favesByDay="favesByDay" :nowTime="now" :sortByTime="favesSort" :days="days" v-on:manageFavourite="manageFavourite" v-on:clearFaves="clearFaves" v-on:setFavesSort="setFavesSort" v-on:toggleFavesListState="toggleFavesListState"></faves-list>
    </swiper-slide>
    <swiper-slide id="show-days">
        <stages-by-day v-for="day in days" :key="day.date" :dayOffset="dayOffset" :stages="byStage" :today="day" :nowTime="now" :faves="faves" v-on:manageFavourite="manageFavourite" v-show="days.indexOf(day) == activeDay &! showAZ &! showTimeline"></stages-by-day>
        <timeline-view v-for="day in days" :key="day.date" :stages="byStage" :today="day" :nowTime="now" :dayStart="tlDayStart" :dayEnd="tlDayEnd" :dateStart="tlDateStart" :dateEnd="tlDateEnd" :timeMarker="timeMarker" :dayInSeconds="dayInSeconds" :faves="faves" v-show="days.indexOf(day) == activeDay &! showAZ && showTimeline"></timeline-view>
        <azacts-list :shows="az" :faves="faves" :showAZ="showAZ" v-on:manageFavourite="manageFavourite" v-show="showAZ"></azacts-list>
    </swiper-slide>
    <swiper-slide>
        <now-next :stages="byStage" :nowTime="now" :faves="faves" v-on:manageFavourite="manageFavourite"></now-next>
    </swiper-slide>
</swiper>
</template>
<script>
import showjson from './data/beautifuldays2024.json';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
const dayNames = [{short:'Sun',long:'Sunday'},{short:'Mon',long:'Monday'},{short:'Tue',long:'Tuesday'},{short:'Wed',long:'Wednesday'},{short:'Thu',long:'Thursday'},{short:'Fri',long:'Friday'},{short:'Sat',long:'Saturday'}];
function dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substring(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        } else {
            return a[property].localeCompare(b[property]);
        }        
    }
}
function tomorrow(date) {
  var result = new Date(date);
  result.setDate(result.getDate() + 1);
  return result;
}
export default {
    name: 'lineup-app',
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            jsonimport: showjson,
            now: new Date(),
            dayOffset: '08:00',
            tlDayStart: '11:00',
            tlDayEnd: '23:59',
            tlDateStart: new Date(),
            tlDateEnd: new Date(),
            shows: [],
            byStage: [],
            faves: [],
            favesListState: [],
            activeDay: 0,
            currentSlide: 1,
            timer: setInterval(this.whatTimeIsIt,10000),
            excludeDates: ['2024-08-15','2024-08-19'],
            excludeStages: ["Fiddlers Arms","Rebel Tent","Dirty Davey's"],
            timeMarker: 0,
            online: false,
            pageControl: null,
            showAZ: false,
            showTimeline: true,
            favesSort: true,
            darkMode: false,
            errors: []
        }
    },
    created() {
        new Image().src='assets/star-empty.svg';
        new Image().src='assets/star-filled.svg';
    },
    beforeMount() {
        var d = this.jsonimport;
        this.processShows(d.modified,d.locations);
        this.tlDateStart.setHours(this.tlDayStart.substring(0,2),this.tlDayStart.substring(3));
        this.tlDateEnd.setHours(this.tlDayEnd.substring(0,2),this.tlDayEnd.substring(3));
    },
    mounted() {
        this.getFavesListState();
        this.moveMarker();
    },
    computed: {
        dayInSeconds() {
        return (this.tlDateEnd-this.tlDateStart)/1000;
        },
        az() {
            const azActs = [...this.shows].sort(dynamicSort('name'));
            return azActs;
        },
        timeSort() {
            const timeActs = [...this.shows].sort(dynamicSort('start'));
            return timeActs;
        },
        listFaves() {
            var favesList = [];
            for (var i=0;i<this.az.length;i++) {
                if (this.faves.includes(this.az[i].id)) {
                    favesList.push(this.az[i]);
                }
            }
            return this.checkClashes(favesList);
        },
        favesByDay() {
            var favesDays = [];
            var f = [...this.listFaves].sort(dynamicSort('start'));
            var ls = this.favesListState;
            for (var d=0;d<this.days.length;d++) {
                var tlDayStart = this.days[d].date + ' ' + this.dayOffset;
                var tomo = tomorrow(tlDayStart);
                var tlDayEnd = tomo.getFullYear() + '-' + ('00' + (tomo.getMonth()+1)).slice(-2) + '-' + ('00' + tomo.getDate()).slice(-2) + ' ' + this.dayOffset;
                var fs = f.filter(s => (tlDayStart <= s.start && s.end < tlDayEnd));
                if (fs.length > 0) {
                    var o = true;
                    if (ls.includes(this.days[d].day)) {
                        o = false;
                    }
                    favesDays.push({day:this.days[d].day,open:o,shows:fs});
                }
            }
            return favesDays;
        },
        dayActs(d) {
          var ts = [];
          var ta = [];
          for (var i=0;i<this.acts.length;i++) {
              if (this.acts[i].date == d.date &! ts.includes(this.acts[i].stage)) {
                  ts.push(this.acts[i].stage);
              }
          }
          for (i=0;i<ts.length;i++) {
              ta.push({stage:ts[i],acts:[]});
              for (var j=0;j<this.acts.length;j++) {
                  if (this.acts[j].date == d.date && ts[i] == this.acts[j].stage) {
                      ta[i].acts.push(this.acts[j]);
                  }
              }
          }
          return ta;
        },
        days() {
            var evDays = [];
            var eventDays = [];
            for (var i=0;i<this.shows.length;i++) {
                var showDate = this.shows[i].start.substring(0,10);
                if (!evDays.includes(showDate)) {
                    evDays.push(showDate);
                    var showDay = new Date(showDate);
                    var dayIdx = showDay.getDay();
                    eventDays.push({day:dayNames[dayIdx].long,date:showDate});
                }
            }
            return eventDays;
        },
        stages() {
            var stages = [];
            this.byStage.forEach(function(st) {
                stages.push(st.stage);
            });
            return stages;
        }
    },
    watch: {
        now() {
            this.moveMarker();
        }
    },
    methods: {
        processShows(m,s) { // m = modified date, s = data
            localStorage.setItem('data',JSON.stringify(s));
            localStorage.setItem('modified',JSON.stringify(m));
            var allShows = [];
            var byStage = [];
            var stageShows = [];
            for (var i=0;i<s.length;i++) {
                if (this.excludeStages.includes(s[i].name)) {
                    continue;
                }
                stageShows = [];
                for (var j=0;j<s[i].events.length;j++) {
                    var thisEvent = s[i].events[j];
                    if (this.excludeDates.includes(thisEvent.start.substring(0,10))) continue;
                    var sh = {id:thisEvent.short};                      // create new event and give it an ID
                    sh.idx = i;                                         // give the show a stage index
                    sh.name = thisEvent.name;                           // and a name
                    sh.stage = s[i].name;                               // and a stage name
                    sh.date = thisEvent.start.substring(0,10);          // and a date
                    var eventStart = new Date(sh.date);                 // date object
                    sh.day = dayNames[eventStart.getDay()].long;    
                    sh.start = thisEvent.start;                         // start time
                    sh.end = thisEvent.end;                             // end time
                    var thisStart = sh.start.substring(11);             // Set time span
                    var thisEnd = sh.end.substring(11);                 // ..
                    sh.timespan = thisStart+'-'+thisEnd;                // text timespan
                    stageShows.push(sh);
                    allShows.push(sh);
                }
                byStage.push({stageid:i,stage:s[i].name,shows:stageShows});
            }
            this.shows = allShows;
            this.byStage = byStage;
            this.getToday();
            if (localStorage.getItem('faves')) {
                this.faves = JSON.parse(localStorage.getItem('faves'));
            }
            if (localStorage.getItem('favesSort')) {
                this.favesSort = eval(localStorage.getItem('favesSort'));
            } else {
                localStorage.setItem('favesSort', this.favesSort);
            }
            if (localStorage.getItem('darkMode')) {
                this.darkMode = eval(localStorage.getItem('darkMode'));
                this.setTheme();
            } else {
                localStorage.setItem('darkMode', this.darkMode);
            }
            if (localStorage.getItem('timelineView')) {
                this.showTimeline = eval(localStorage.getItem('timelineView'));
            } else {
                localStorage.setItem('timelineView', this.showTimeline);
            }
        },
        showsByDay(day) {
            var todayHere = [];
            for (var i=0;i<this.shows.length;i++) {
                if (this.shows[i].start.substring(0,10) == day) {
                    todayHere.push(this.shows[i]);
                }
            }
            return todayHere;
        },
        changeDay(d) {
            document.querySelector("#show-days").scrollTo(0,0);
            this.activeDay=d;
            this.showAZ=false;
            this.goToMain();
        },
        showAtoZ() {
            document.querySelector("#show-days").scrollTo(0,0);
            this.showAZ=true;
            this.goToMain();
        },
        goToMain() {
            this.pageControl.slideTo(1);
        },
        checkClashes(c) {
            var cl = [];
            for (var i = 0; i < c.length; i++) {
                var aStart = c[i].start;
                var aEnd = c[i].end;
                for(var j = 0; j < c.length; j++) {
                    if (c[j] === c[i]) {
                        continue;
                    } else {
                        var bStart = c[j].start;
                        var bEnd = c[j].end;
                        if (((aStart <= bStart) && (bStart < aEnd)) || ((aStart < bEnd) && (bEnd <= aEnd))) {
                        if (!cl.includes(c[i].id)) {
                            cl.push(c[i].id);
                        }
                        if (!cl.includes(c[j].id)) {
                            cl.push(c[j].id);
                        }
                        }
                    }
                }
            }
            return c;
        },
        manageFavourite(ide) {
            var favesTemp = this.faves;
            if (favesTemp.includes(ide)) {
                favesTemp.splice(favesTemp.indexOf(ide),1);
            } else {
                favesTemp.push(ide);
                favesTemp.sort();
            }
            localStorage.setItem('faves',JSON.stringify(this.faves));
        },
        clearFaves() {
            this.faves = [];
            localStorage.removeItem('faves');
        },
        getToday() {
            var today = this.whatTimeIsIt();
            const dateFormat = today.getFullYear() + '-' + ('00' + (today.getMonth()+1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2);
            for (var i=0;i<this.days.length;i++) {
                if (this.days[i].date == dateFormat) {
                    this.activeDay = i;
                }
            }
            return dateFormat;
        },
        onSwiper(swiper) {
            this.pageControl = swiper;
        },
        onSlideChange(swiper) {
            this.currentSlide = swiper.activeIndex;
        },
        whatTimeIsIt() {
            this.now = new Date(); // '2024-08-18 20:23'
            return this.now;
        },
        setFavesSort(f) {
            this.favesSort = f;
            localStorage.setItem('favesSort', this.favesSort);
        },
        switchColourMode() {
            this.darkMode = !this.darkMode;
            localStorage.setItem('darkMode', this.darkMode);
            this.setTheme();
        },
        switchDayView() {
            this.showTimeline = !this.showTimeline;
            document.querySelector("#show-days").scrollTo(0,0);
            localStorage.setItem('timelineView', this.showTimeline);
            
        },
        setTheme() {
            var theme = 'light';
            if (this.darkMode) theme = 'dark'
            var dom = document.querySelector('html');
            dom.setAttribute('data-bs-theme', theme);
        },
        toggleFavesListState(dy) {
            var ls=this.favesListState;
            if (ls.includes(dy)) {
                ls.splice(ls.indexOf(dy),1);
            } else {
                ls.push(dy);
            }
            this.favesListState = ls;
            this.setFavesListState();
        },
        getFavesListState() {
            if (localStorage.getItem('favesListState')) {
                this.favesListState = JSON.parse(localStorage.getItem('favesListState'));
            }
        },
        setFavesListState() {
                localStorage.setItem('favesListState',JSON.stringify(this.favesListState));
        },
        moveMarker() {
        if (this.tlDateStart <= this.now && this.now <= this.tlDateEnd) {
            var nowMark = (this.now - this.tlDateStart)/1000;
            this.timeMarker = ((nowMark/this.dayInSeconds)*1950)-2;
        }
    }
    }
}
</script>

<style>

</style>
<template>
<section class="container-fluid now-next">
    <h3 class="page-title sticky-top">Now & Next</h3>
    <div v-for="stage,index in nowNextShows" :key="stage.stage" :class="'hcol-' + index">
      <h4>{{ stage.stage }}</h4>
      <div class="row list-group list-group-flush">
          <div class="list-group-item d-flex justify-content-between align-items-stretch now-hilite" v-if="stage.now != undefined">
            <div class="act-time flex-shrink-0"><span class="badge on-stage">ON NOW</span><br><span class="text-smaller">until {{ stage.now.timespan.substring(6)}}</span></div>
            <div class="d-flex align-items-center flex-grow-1 act-name">{{ stage.now.name }}</div>
            <div class="ms-4 align-self-center flex-shrink-0" :class="stage.now.name != undefined && faves.includes(stage.now.id) ? 'icon-star-filled' : ''"></div>
            </div>
            <div class="list-group-item d-flex justify-content-between align-items-stretch next-hilite" v-if="prePostEventCheck(stage.next)">
            <div class="flex-grow-1 act-name"><strong>{{ stage.next != undefined ? stage.next.name : endEvtMsg }}</strong><br><span class="act-details"><strong>{{ stage.next.day }}</strong> {{ stage.next.timespan.substring(0,5) }}</span></div>
            <div class="ms-4 align-self-center flex-shrink-0" :class="stage.next != undefined && faves.includes(stage.next.id) ? 'icon-star-filled' : ''"></div>
            </div>
            <div v-else class="list-group-item d-flex justify-content-between align-items-stretch next-hilite">
            <div class="act-time flex-shrink-0 d-flex align-items-center">{{stage.next != undefined ? stage.next.timespan : 'Next'}}</div>
            <div class="d-flex align-items-center flex-grow-1 act-name"><strong>{{ stage.next != undefined ? stage.next.name : endEvtMsg }}</strong></div>
            <div class="ms-4 align-self-center flex-shrink-0" :class="stage.next != undefined && faves.includes(stage.next.id) ? 'icon-star-filled' : ''"></div>
          </div>
        </div>
    </div>
  <div id="qr" class="d-flex flex-column">
        <img src="assets/qr-code.svg">
    </div>
</section>
</template>

<script>
export default ({
    name: 'now-next',
    props: {stages: Array,nowTime:Date,faves: Array},
    data() {
      return {
        today: '',
        nowNextShows: [],
        endEvtMsg: 'No more acts'
      }
    },
    mounted() {
        this.checkDate();
    },
    watch: {
      stages() {
        this.updateData();
      },
      nowTime() {
        this.updateData();
      }
    },
    computed: {
        listStages() {
            var ret = [];
            for (var n = 0;n < this.stages.length;n++) {
              ret.push({stage:this.stages[n].stage,stageid:this.stages[n].stageid,now:this.listNow(this.stages[n].stageid),next:this.listNext(this.stages[n].stageid)})
            }
          return ret;
          }
    },
    methods: {
      listNow(stid) {
          var sh = this.stages.filter(s => s.stageid == stid);
          var nowsh = sh[0].shows.filter(s => s.start <= this.formatTime() && this.formatTime() < s.end);
          return nowsh[0];
        },
      listNext(stid) {
          var sh = this.stages.filter(s => s.stageid == stid);
          var nxsh = sh[0].shows.filter(s => s.start > this.formatTime());
         return nxsh[0];
      },
      checkDate() {
        if (this.today != this.formatTime().substring(0,10)) {
          this.today = this.formatTime().substring(0,10);
          this.updateData();
        }
      },
      updateData() {
        console.log('updating...');
        this.nowNextShows = this.listStages;
      },
      formatTime() {
      return this.nowTime.getFullYear() + '-' + ('00' + (this.nowTime.getMonth()+1)).slice(-2) + '-' + ('00' + this.nowTime.getDate()).slice(-2) + ' ' + ('00' + this.nowTime.getHours()).slice(-2) + ':' + ('00' + this.nowTime.getMinutes()).slice(-2);
    },
    prePostEventCheck(sh) {
            if (sh == undefined) {
              return false;
            }
            if (sh.date > this.formatTime().substring(0,10)) {
              return true;
            }
            return false;
          }
    }
})
</script>

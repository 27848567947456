<template>
<section class="container-fluid day" :id="today.day">
   <div v-for="st,index in stageData" :key="st.stage" :class="'hcol-' + index">
      <h4 class="page-title sticky-top">{{ st.stage }}</h4>
      <div class="row list-group list-group-flush stage-list">
          <div class="list-group-item d-flex justify-content-between align-items-stretch"  v-for="show in st.shows" :key="show.id" :class="{'on-now': this.onNow.includes(show.id), 'elapsed': show.end < this.nowFormatted}" :data-id="show.id">
            <div class="act-time flex-shrink-0 d-flex align-items-center">{{ show.timespan }}</div>
            <div class="d-flex align-items-center flex-grow-1"><strong>{{ show.name }}&nbsp;&nbsp;&nbsp;<span class="badge on-stage" :class="{'force-none': !this.onNow.includes(show.id)}">ON NOW</span></strong></div>
            <div class="ms-4 align-self-center flex-shrink-0 clickable" @click.stop="$emit('manageFavourite',show.id)" :class="faves.includes(show.id) ? 'icon-star-filled' : 'icon-star-empty'"></div>
          </div>
      </div>
    </div>
</section>
</template>

<script>
function tomorrow(date) {
  var result = new Date(date);
  result.setDate(result.getDate() + 1);
  return result;
}
export default {
  name: "stages-by-day",
  props: {stages: Array,nowTime:Date,today: Object,dayOffset: String,faves: Array},
  data() {
    return {
      stageData: [],
      dayStart: '',
      dayEnd: '',
      nowFormatted: ''
    }
  },
  mounted() {
        this.updateData();
        this.formatNow();
  },
  watch: {
    stages() {
      this.updateData();
    },
    nowTime() {
      this.formatNow();
    }
  },
  computed: {
    onNow() {
      var nn = [];
      for (var i=0;i<this.stageData.length;i++) {
        var stsh = this.stageData[i].shows;
        var onw = stsh.filter(s => s.start <= this.nowFormatted && this.nowFormatted <= s.end);
        if (onw.length > 0) {
          nn.push(onw[0].id);
        }
      }
      return nn;
    }
  },
  methods: {
      updateData() {
        this.dayStart = this.today.date + ' ' + this.dayOffset;
        var tomo = tomorrow(this.dayStart);
        this.dayEnd = tomo.getFullYear() + '-' + ('00' + (tomo.getMonth()+1)).slice(-2) + '-' + ('00' + tomo.getDate()).slice(-2) + ' ' + this.dayOffset;
        for (var i=0;i<this.stages.length;i++) {
        var ts = this.stages[i].shows.filter(s => (this.dayStart <= s.start && s.end < this.dayEnd));
        if (ts.length > 0) {
            // sort desc        ts.sort(function(a, b) { 
            //                  var keyA = a.start,
            //                  keyB = b.start;
                                // Compare the 2 dates
            //                  if (keyA < keyB) return 1;
            //                  if (keyA > keyB) return -1;
            //                  return 0;
            //  });
        this.stageData.push({stageid:this.stages[i].stageid,stage:this.stages[i].stage,shows:ts});
          }
        }
      },
      formatNow() {
        var nt = this.nowTime;
        this.nowFormatted = nt.getFullYear() + '-' + ('00' + (nt.getMonth()+1)).slice(-2) + '-' + ('00' + nt.getDate()).slice(-2) + ' ' + ('00' + (nt.getHours())).slice(-2) + ':' + ('00' + (nt.getMinutes())).slice(-2);
      }
  },
};
</script>

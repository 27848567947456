<template>
<section class="container-fluid favourites">
<h3 class="page-title faves-h sticky-top">Favourites</h3>
<div v-if="faveShows.length > 0">
    <div id="faves-switch" class="btn-group" role="group" aria-label="Sort order">
        <button type="button" class="btn btn-danger btn-sm" :class="{active: sortByTime}" @click.stop="$emit('setFavesSort',true)">By Day</button>
        <button type="button" class="btn btn-danger btn-sm" :class="{active: !sortByTime}" @click.stop="$emit('setFavesSort',false)">A-Z</button>
    </div>
    <div v-show="!sortByTime">
    <div class="row list-group list-group-flush faves-list">
          <div class="list-group-item d-flex justify-content-between align-items-stretch" v-for="show in faveShows" :key="show.id" :class="{'warning': checkClashes.includes(show.id) && show.end > this.nowFormatted, 'elapsed': show.end < this.nowFormatted}" :data-id="show.id">
            <div class="d-flex flex-grow-1 align-items-center"><span><strong>{{ show.name }}</strong><br><span class="badge bg-danger" v-show="checkClashes.includes(show.id) && show.end > this.nowFormatted">Clash</span></span></div>
            <div class="flex-shrink-0 fave-time" :class="'hcol-' + show.idx"><span class="act-stage"><strong>{{ show.stage }}</strong></span><br><span class="act-details"><strong>{{ show.day }}</strong><br>{{ show.timespan }}</span></div>
            <div class="ms-4 align-self-center flex-shrink-0 clickable icon-star-filled" @click.stop="$emit('manageFavourite',show.id)"></div>
          </div>
    </div>
    </div>
    <div v-show="sortByTime">
        <div v-for="day in favesByDay" :key="day">
            <h4 class="faves-d sticky-top">{{ day.day }}<span class="float-end icon-expand clickable" :class="day.open == true ? 'contract' : 'expand'" @click.stop="$emit('toggleFavesListState',day.day)"></span></h4>
            <div class="row list-group list-group-flush faves-list" v-show="day.open == true">
                <div class="list-group-item d-flex justify-content-between align-items-stretch" v-for="show in day.shows" :key="show.id" :data-id="show.id" :class="{'warning': checkClashes.includes(show.id) && show.end > this.nowFormatted, 'elapsed': show.end < this.nowFormatted}">
                <div class="d-flex flex-row flex-grow-1 align-items-center"><span><strong>{{ show.name }}</strong><br><span class="badge bg-danger" v-show="checkClashes.includes(show.id) && show.end > this.nowFormatted">Clash</span></span></div>
                <div class="flex-shrink-0 fave-time" :class="'hcol-' + show.idx"><span class="act-stage"><strong>{{ show.stage }}</strong></span><br><span class="act-details">{{ show.timespan }}</span></div>
                <div class="ms-4 align-self-center flex-shrink-0 clickable icon-star-filled" @click.stop="$emit('manageFavourite',show.id)"></div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div class="row list-group list-group-flush" v-else>
      <div class="list-group-item">
      You haven't added any favourites yet.<br>Click on the star next to an act in list view to add.
      </div>
  </div>
    <div id="btn-reset-faves" class="d-flex flex-column" v-show="faveShows.length> 0">
        <button type="button" class="btn btn-danger" @click.stop="$emit('clearFaves')">Clear favourites</button>
    </div>
</section>
</template>

<script>
export default {
    name: 'faves-list',
    props: {faveShows: Array,nowTime:Date,favesByDay: Array,sortByTime: Boolean,days: Array},
    data() {
    return {
      nowFormatted: ''
    }
  },
    mounted() {
        this.formatNow();
    },
    computed: {
        checkClashes() {
            var cl = [];
            var c = this.faveShows;
            for (var i = 0; i < c.length; i++) {
                var aStart = c[i].start;
                var aEnd = c[i].end;
                for(var j = 0; j < c.length; j++) {
                    if (c[j] === c[i]) {
                        continue;
                    } else {
                        var bStart = c[j].start;
                        var bEnd = c[j].end;
                        if (((aStart <= bStart) && (bStart < aEnd)) || ((aStart < bEnd) && (bEnd <= aEnd))) {
                        if (!cl.includes(c[i].id)) {
                            cl.push(c[i].id);
                        }
                        if (!cl.includes(c[j].id)) {
                            cl.push(c[j].id);
                        }
                        }
                    }
                }
            }
            return cl;
        }
    },
    watch: {
        nowTime() {
        this.formatNow();
    }
    },
    methods: {
        formatNow() {
            var nt = this.nowTime;
            this.nowFormatted = nt.getFullYear() + '-' + ('00' + (nt.getMonth()+1)).slice(-2) + '-' + ('00' + nt.getDate()).slice(-2) + ' ' + ('00' + (nt.getHours())).slice(-2) + ':' + ('00' + (nt.getMinutes())).slice(-2);
        }
    }
}
</script>